<template>
  <div class="row">
    <div class="form-group col-md-4">
      <label for="temporada">Temporada</label>
      <select
        v-model="selectedTemporada"
        class="form-control form-control-sm"
        @change="onSelectTemporada"
      >
        <option value="" disabled>Seleccione temporada</option>
        <option value="1">Invierno</option>
        <option value="2">Verano</option>
      </select>
    </div>

    <div v-if="selectedTemporada" class="form-group col-md-4">
      <label for="horas_vigencia">Horas de Vigencia</label>
      <input
        type="number"
        class="form-control form-control-sm"
        v-model="horasVigencia"
        min="1"
      />
    </div>

    <div v-if="selectedTemporada && horasVigencia" class="col-md-2">
      <button
        class="btn btn-success"
        @click="guardarTemporada"
        :disabled="!horasVigencia"
      >
        Guardar Temporada
      </button>
    </div>

    <div v-if="temporadas && temporadas.length > 0" class="col-md-12 mt-3">
      <h5>Temporadas Registradas:</h5>
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Temporada</th>
            <th>Horas de Vigencia</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(temporada, index) in temporadas" :key="index">
            <td>{{ temporada.descripcion }}</td>
            <td>{{ temporada.pivot.horas_vigencia }} horas</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Temporada",

  data() {
    return {
      selectedTemporada: "",
      horasVigencia: null,
      temporadas: [],
    };
  },
  mounted() {
    this.getIndex();
  },
  methods: {
    getIndex() {
      if (this.$parent.ruta && this.$parent.ruta.temporadas) {
        this.temporadas = this.$parent.ruta.temporadas;
      } else {
        this.temporadas = [];
      }
    },

    onSelectTemporada() {
      const temporadaExistente = this.temporadas.find(
        (temp) => String(temp.pivot.temporada_id) === String(this.selectedTemporada)
      );

      if (temporadaExistente) {
        this.horasVigencia = temporadaExistente.pivot.horas_vigencia;
      } else {
        this.horasVigencia = null;
      }
    },

    guardarTemporada() {
      const temporadaData = {
        ruta_id: this.$parent.id,
        temporada_id: this.selectedTemporada,
        horas_vigencia: this.horasVigencia,
      };

      axios
        .post(`api/admin/rutas/${this.$parent.id}/temporadas`, temporadaData)
        .then(() => {
          this.$swal("Éxito", "Datos guardados correctamente", "success");
          this.$parent.getIndex(); // Refrescar la lista
        })
        .catch((error) => {
          this.$swal("Error", error.response.data.message || "Ocurrió un error", "error");
        });

      this.selectedTemporada = "";
      this.horasVigencia = null;
    },
  },
};
</script>

<style scoped>
.table {
  width: 100%;
  margin-top: 15px;
}
</style>